import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import DetailsInformationCard from 'components/Shared/DetailsInformationCard';
import DetailsInformationTable from 'components/Shared/DetailsInformationTable';
import DetailsInformationTextCard from 'components/Shared/DetailsInformationTextCard';
import ListingNotification from 'components/BiddingProcess/ListingNotification';
import './styles.scss';

const FoodSafetyDetails = ({
  showFoodSafetyDetailsWarning,
  batchDetails,
  organicData,
  microbiologicalTestResult,
  chemicalTestResult,
  heavyMetalTestResult,
  complianceInstitute,
}) => {
  const getTableHeaderData = (firstColumnName) => [
    {
      id: 1,
      title: firstColumnName,
      align: 'left',
    },
    {
      id: 2,
      title: t('bidding_process.test_method'),
      align: 'left',
    },
    {
      id: 3,
      title: t('bidding_process.test_count'),
      align: 'left',
    },
    {
      id: 4,
      title: t('bidding_process.max_limit'),
      align: 'left',
    },
  ];

  return (
    <div className="food-safety-details">
      <div className="food-safety-details__title">{t('bidding_process.food_safety_details')}</div>

      {showFoodSafetyDetailsWarning && (
        <div className="food-safety-details__listing-notification">
          <ListingNotification
            notificationSubHeader={t('bidding_process.food_safety_not_added')}
          />
        </div>
      )}

      <DetailsInformationCard
        cardHeader={t('bidding_process.organic')}
        detailsData={organicData}
      />

      <DetailsInformationCard
        cardHeader={t('bidding_process.batch_details')}
        detailsData={batchDetails}
      />

      <div className="food-safety-details__table">
        <DetailsInformationTable
          data={microbiologicalTestResult}
          headerData={getTableHeaderData('Organism')}
          captionText={t('bidding_process.microbiological_test_results')}
        />

        <DetailsInformationTable
          data={chemicalTestResult}
          headerData={getTableHeaderData('Chemical')}
          captionText={t('bidding_process.chemical_test_results')}
        />

        {heavyMetalTestResult.length ? (
          <DetailsInformationTable
            data={heavyMetalTestResult}
            headerData={getTableHeaderData('Metal')}
            captionText={t('bidding_process.heavy_metal_level_test_results')}
          />
        ) : null}
      </div>

      <div className="food-safety-details__certificates">
        <DetailsInformationTextCard complianceInstitute={complianceInstitute} />
      </div>
    </div>
  );
};

FoodSafetyDetails.propTypes = {
  showFoodSafetyDetailsWarning: PropTypes.bool.isRequired,
  batchDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired,
    }),
  ).isRequired,
  organicData: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.string.isRequired,
    }),
  ).isRequired,
  microbiologicalTestResult: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      testItem: PropTypes.string.isRequired,
      testMethod: PropTypes.string.isRequired,
      testCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      maxLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    }),
  ).isRequired,
  chemicalTestResult: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      testItem: PropTypes.string.isRequired,
      testMethod: PropTypes.string.isRequired,
      testCount: PropTypes.number.isRequired,
      maxLimit: PropTypes.number.isRequired,
    }),
  ).isRequired,
  heavyMetalTestResult: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      testItem: PropTypes.string.isRequired,
      testMethod: PropTypes.string.isRequired,
      testCount: PropTypes.number.isRequired,
      maxLimit: PropTypes.number.isRequired,
    }),
  ).isRequired,
  complianceInstitute: PropTypes.string.isRequired,
};

export default FoodSafetyDetails;
