import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/BaseComponents/Icon';
import './styles.scss';

const ListingNotification = ({ notificationHeader, notificationSubHeader }) => (
  <div className="listing-notification flex justify-between items-center">
    <div className="flex items-center gap-14px">
      <Icon name="neutralOutline" className="listing-notification__warning-icon" />
      <div>
        {notificationHeader && <p className="listing-notification__heading">{notificationHeader}</p>}
        {notificationSubHeader && <p className="listing-notification__description">{notificationSubHeader}</p>}
      </div>
    </div>
  </div>
);


ListingNotification.defaultProps = {
  notificationHeader: null,
  notificationSubHeader: null,
};

ListingNotification.propTypes = {
  notificationHeader: PropTypes.string,
  notificationSubHeader: PropTypes.string,
};

export default ListingNotification;
