import React from 'react';
import { t } from 'i18next';
import {
  ADDITIONAL_DOCUMENT_FORM_KEY,
  BUSINESS_CONTACTS_FORM_KEY,
  BUSINESS_DETAILS_FORM_KEY,
  FOOD_SAFETY_FORM_KEY,
  PERSONAL_DETAILS_FORM_KEY,
  PERMISSIONS_FORM_KEY,
  BANKING_DETAILS_FORM_KEY,
  COMPANY_MANAGEMENT_FORM_KEY,
} from 'utils/constants/portals';
import {
  BUSINESS_CONTACTS_TAB_SUBJECT,
  BUSINESS_DETAILS_TAB_SUBJECT,
  FOOD_SAFETY_TAB_SUBJECT,
  PERMISSIONS_TAB_SUBJECT,
  PERSONAL_DETAILS_TAB_SUBJECT,
  ADDITIONAL_DOCUMENT_TAB_SUBJECT,
  BANKING_DETAILS_TAB_SUBJECT,
  COMPANY_MANAGEMENT_TAB_SUBJECT,
} from 'ability';
import PersonalDetailsForm from 'components/Shared/Portals/PersonalDetailsForm';
import BankingDetailsForm from 'components/AffiliatePartner/Profile/Forms/BankingDetailsForm';
import BusinessDetailsForm from 'components/Shared/Portals/BusinessDetailsForm';
import BusinessContacts from 'components/Shared/Portals/BusinessContacts';
import FoodSafetyForm from 'components/Shared/Portals/FoodSafety';
import AdditionalDocumentForm from 'components/Shared/Portals/AdditionalDocument';
import PermissionsForm from 'components/Portals/Admin/UserManagement/Profile/PermissionsForm';
import CompanyManagementForm from 'components/Shared/Portals/CompanyManagementForm';

const getPersonalDetailsTab = ({ userId, isEditable, toastRef }) => (
  {
    getContent: ({ onTabChange }) => (
      <PersonalDetailsForm
        onTabChange={onTabChange}
        userId={userId}
        isEditable={isEditable}
        toastRef={toastRef}
      />
    ),
    key: PERSONAL_DETAILS_FORM_KEY,
    title: t('profile.form_tabs.personal_details'),
  }
);

const getBusinessDetailsTab = ({ companyId, userDetails, isEditable, toastRef }) => (
  {
    getContent: ({ onTabChange }) => (
      <BusinessDetailsForm
        userDetails={userDetails}
        companyId={companyId}
        isEditable={isEditable}
        onTabChange={onTabChange}
        toastRef={toastRef}
      />
    ),
    key: BUSINESS_DETAILS_FORM_KEY,
    title: t('profile.form_tabs.business_details'),
  }
);

const getBusinessContactsTab = ({ companyId, isEditable, userDetails, toastRef }) => (
  {
    getContent: () => (
      <BusinessContacts companyId={companyId} isEditable={isEditable} userDetails={userDetails} toastRef={toastRef} />
    ),
    key: BUSINESS_CONTACTS_FORM_KEY,
    title: t('profile.form_tabs.business_contacts'),
  }
);

const getFoodSafetyTab = ({ companyId, isEditable, userDetails, toastRef }) => (
  {
    getContent: () => <FoodSafetyForm companyId={companyId} isEditable={isEditable} userDetails={userDetails} toastRef={toastRef} />,
    key: FOOD_SAFETY_FORM_KEY,
    title: t('profile.form_tabs.food_safety'),
  }
);

const getPermissionsTab = ({ companyId, userId, toastRef }) => (
  {
    getContent: () => <PermissionsForm companyId={companyId} userId={userId} toastRef={toastRef} />,
    key: PERMISSIONS_FORM_KEY,
    title: t('profile.form_tabs.permissions'),
  }
);

const getAdditionalDocumentTab = ({ companyId, companyDetails, userDetails, isEditable, toastRef }) => (
  {
    getContent: () => (
      <AdditionalDocumentForm
        companyDetails={companyDetails}
        userDetails={userDetails}
        companyId={companyId}
        isEditable={isEditable}
        toastRef={toastRef}
      />
    ),
    key: ADDITIONAL_DOCUMENT_FORM_KEY,
    title: t('profile.form_tabs.additional_document'),
  }
);

const getBankingDetailsTab = ({ companyId, isEditable, userDetails, toastRef }) => (
  {
    getContent: () => (
      <BankingDetailsForm companyId={companyId} isEditable={isEditable} userDetails={userDetails} toastRef={toastRef} />
    ),
    key: BANKING_DETAILS_FORM_KEY,
    title: t('profile.progress_card.banking_details'),
  }
);

const getCompanyManagementTab = ({ companyId, isEditable, toastRef }) => (
  {
    getContent: () => (
      <CompanyManagementForm
        companyId={companyId}
        isEditable={isEditable}
        toastRef={toastRef}
      />
    ),
    key: COMPANY_MANAGEMENT_FORM_KEY,
    title: t('profile.progress_card.company_management'),
  }
);

export const getProfileTabs = (params, ability) => {
  const tabs = [];

  if (ability.can('view', PERSONAL_DETAILS_TAB_SUBJECT)) {
    tabs.push(getPersonalDetailsTab(params));
  }

  if (ability.can('view', BUSINESS_DETAILS_TAB_SUBJECT)) {
    tabs.push(getBusinessDetailsTab(params));
  }

  if (ability.can('view', BUSINESS_CONTACTS_TAB_SUBJECT)) {
    tabs.push(getBusinessContactsTab(params));
  }

  if (ability.can('view', FOOD_SAFETY_TAB_SUBJECT)) {
    tabs.push(getFoodSafetyTab(params));
  }

  if (ability.can('view', BANKING_DETAILS_TAB_SUBJECT)) {
    tabs.push(getBankingDetailsTab(params));
  }

  if (ability.can('view', ADDITIONAL_DOCUMENT_TAB_SUBJECT)) {
    tabs.push(getAdditionalDocumentTab(params));
  }

  if (ability.can('view', PERMISSIONS_TAB_SUBJECT)) {
    tabs.push(getPermissionsTab(params));
  }

  if (ability.can('view', COMPANY_MANAGEMENT_TAB_SUBJECT)) {
    tabs.push(getCompanyManagementTab(params));
  }

  return tabs;
};
