import snakecaseKeys from 'snakecase-keys';

const toSnakecaseKeys = (obj) => snakecaseKeys(obj);
const mergeResponce = (obj1, obj2) => {
  if(!obj1) {
    return obj2;
  }
  if(!obj2) {
    return obj1;
  }

  const answer = {}

  Object.keys(obj1).forEach((key) => {
    if(answer[key] === undefined || answer[key] === null) {
      answer[key] = obj1[key];
    }
  });

  Object.keys(obj2).forEach((key) => {
    if(answer[key] === undefined || answer[key] === null) {
      answer[key] = obj2[key];
    }
  });

  return answer;
}

const mergeFileResponce = (obj1, obj2, obj2Prefix = '') => {
  if(!obj1) {
    return obj2;
  }
  if(!obj2) {
    return obj1;
  }

  const answer = {}

  Object.keys(obj1).forEach((key) => {
    if(answer[key]?.url === undefined) {
      answer[key] = obj1[key];
    }
  });

  Object.keys(obj2).forEach((key) => {
    if(answer[key.replace(obj2Prefix, '')]?.url === undefined || JSON.stringify((answer[key.replace(obj2Prefix, '')]) === "{}")) {
      answer[key.replace(obj2Prefix, '')] = obj2[key];
    }
  });

  return answer;
}

export { toSnakecaseKeys, mergeResponce, mergeFileResponce };
