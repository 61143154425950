import { parseTemplate } from 'url-template';

function url(template, params) {
  const myParams = { ...params };
  if (myParams.format === undefined) {
    myParams.format = 'json';
  }
  return parseTemplate(template).expand(myParams);
}

const routes = {
  auth: {
    signup: () => url('/sign_up'),
    login: () => url('/session'),
    logout: () => url('/session'),
    forgotPassword: () => '/password/request-reset',
    resetPassword: () => '/password/reset',
    verifyEmail: (params) => url('/confirmation?token={token}', params),
  },
  admin: {
    requestAdditionalDoc: (params) => url('/users/{userId}/request_additional_document', params),
    verifyUser: (params) => url('/users/{userId}/verify', params),
    rejectUser: (params) => url('/users/{userId}', params),
    revokeUser: (params) => url('/users/{userId}/revoke', params),
    uploadCSV: (params) => url('/users/{userId}/import_aggregated_data', params),
    updateRoles: (params) => url('/users/{userId}/update_roles', params),
  },
  users: {
    entity: (params) => url('/users/{id}', params),
    getProfileCompleteness: (params) => url('/users/{id}/completeness', params),
    requestUserVerification: (params) => url('/users/{id}/request_verification', params),
    getAllUsers: (params) =>
      url(`/users?page={currentPage}&status={userStatus}&user_group={userGroup}&company_id={companyId}&column={column}&direction={direction}&i`, params),
    getMyAffiliations: (userStatus, page) =>
      url(`/users/on_boarded_users?status=${userStatus}&page=${page}`),
    getOnboardedUsers: (id, userStatus, page) =>
      url(`/users/${id}/on_boarded_users?status=${userStatus}&page=${page}`),
    reInvite: (params) => url('/users/{id}/re_invite', params),
    completeSignUp: () => url('/complete_sign_up'),
    getDraft: (params) => url('users/{id}/draft/{?name}', params),
    draft: (params) => url('users/{id}/draft', params),
  },
  currentUser: {
    entity: () => url('/me'),
  },
  companies: {
    entity: (params) => url('/companies/{id}', params),
    businessContacts: (params) => url('/companies/{id}/business_contacts', params),
    bankingDetails: (params) => url('/companies/{company_id}/banking_detail', params),
    additionalDocuments: (params) => url('/companies/{id}/additional_documents', params),
    companyPermissions: (params) => url('/companies/{id}/company_permissions', params),
    religionSertificates: (params) => url('/companies/{id}/religion_certificates', params),
    inviteCompanyMember: (params) => url('/companies/{id}/invite_company_member', params),
  },
  foodSafety: {
    entity: (params) => url('/companies/{id}/food_safety', params),
    attachmentDelete: (params) => url('/companies/{id}/food_safety/{attachment_id}', params),
  },
  products: {
    entity: (params) =>
      url(
        '/products{?page,per_page,column,direction,with_price_graph_data,with_volume_graph_data,with_filter_stats,my_listings,for_listings,listing_type,product_type,time_period,haccp,fssc,brc,fcl,lcl,style_0,style_1,style_1s,style_2,style_4,style_4r,style_4l,style_5,style_6,style_7,style_8,other_nut_style,organic,less_than_18mm,size_18_20mm,size_20_22mm,size_22_25mm,size_25mm_plus,size_22mm_plus,size_20mm_plus,size_18mm_plus,other_size,nut_grade,halaal,kosher,harvest_season,processed_season,best_before_date,min_price_per_kg,max_price_per_kg,min_quantity,max_quantity,payment_option,brc_level,region,country,destination_country,size,sound_kernel_recovery,unsound_kernel_recovery,hybrid,tree_hybrid_cultivar,beaumont,integrifolia,tree_integ_cultivar,other_tree_variant,global_gap,ppecb,esg,dap,days_30,days_60,days_90,days_180,custom_payment_option,premium,commercial,rejected,shipping_start_date,shipping_end_date,pasteurized}',
        params,
      ),
    details: (params) => url('/products/{product_id}', params),
    createProduct: (params) => url('/products', params),
    foodSafety: (params) => url('/products/{productId}/product_food_safety', params),
    foodSafetyTestValidate: () => url('/food_safety_tests/validate'),
    attachmentDelete: (params) => url('/attachments/{attachmentId}', params),
    similarProducts: (params) => url('/products/{product_id}/similar_products', params),
  },
  listings: {
    getMyListings: (params) =>
      url(
        '/listings/my_listings?page={currentPage}&per_page={perPage}&listing_status={productStatus}&category={category}&column={column}&direction={direction}',
        params,
      ),
    getCompanyListings: (params) =>
      url(
        '/listings/company_listings?page={currentPage}&per_page={perPage}&listing_status={productStatus}&category={category}&column={column}&direction={direction}',
        params,
      ),
    details: (params) => url('/listings/{listing_id}', params),
    publish: (params) => url('/listings/{listing_id}/publish', params),
    contactAdmin: (params) => url('/listings/{listing_id}/contact_admin', params),
    unPublish: (params) => url('/listings/{id}/unpublish', params),
    placeBid: (params) => url('/listings/{id}/place_bid', params),
    retractBid: (params) => url('/listings/{id}/retract_bid', params),
    rejectBid: (params) => url('/listings/{id}/reject_bid', params),
    acceptOffer: (params) => url('/listings/{id}/accept_offer', params),
    updateOfferPrice: (params) => url('/listings/{id}/update_offer_price', params),
    follow: (params) => url('/listings/{id}/follow', params),
    unfollow: (params) => url('/listings/{id}/unfollow', params),
    following: (params) => url('listings/following?page={currentPage}&per_page={perPage}&column={sortColumn}&direction={sortDirection}&is_company={isCompany}', params),
    leading_bids: (params) => url('listings/leading_bids?page={currentPage}&per_page={perPage}&column={sortColumn}&direction={sortDirection}&is_company={isCompany}', params),
    outbid: (params) => url('listings/outbid?page={currentPage}&per_page={perPage}&column={sortColumn}&direction={sortDirection}&is_company={isCompany}', params),
    delete: (params) => url('/listings/{id}', params)
  },
  contract: {
    contracts: (params) => url('/contracts{?page,per_page,product_type,column,direction}', params),
    details: (params) => url('/contracts/{id}', params),
    uploadSignedContract: (params) =>
      url('/contracts/{contract_id}/upload_signed_contract', params),
    requestAssistance: (params) => url('/contracts/{contract_id}/request_assistance', params),
    getContractPdf: (params) => url('/contracts/{contract_id}/pdf_details', params),
    updateContractDocuments: (params) => url('/contracts/{contract_id}/upload_document', params),
    renameContractDocument: (params) => url('/contracts/{contract_id}/rename_document', params),
    uploadDraftContractPdf: (params) =>
      url('/contracts/{contract_id}/upload_draft_contract', params),
    changeContractStatus: (params) =>
      url('/contracts/{contract_id}/change_contract_status', params),
  },
  affiliatePartner: {
    inviteBuyer: () => url('/users/send_invitation'),
  },
};

export default routes;
