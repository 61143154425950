import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useUpdateBankingDetailsMutation, useGetCompanyQuery } from 'api/companies';
import { useDraftMutation, useGetDraftQuery } from 'api/users';
import { mergeResponce } from 'utils/helper/ObjectKeyConverter';
import { processPortalFormSuccess } from 'utils/helper/FormHandlers';
import { handleServerValidationErrors, requiredField } from 'utils/helper/Validations';
import { errorToastConfig } from 'utils/constants/ToastConfig';
import Button from 'components/BaseComponents/Button';
import Input from 'components/BaseComponents/Input';
import Toastify from 'components/Shared/ToastNotification/Toastify';
import { generateProfileDraftBody } from 'utils/helper/RequestBody';

const BankingDetailsForm = ({ companyId, isEditable, userDetails, toastRef }) => {
  const draftName = "BankingDetailsForm"
  const { t } = useTranslation();
  const { data: companyDetails = {} } = useGetCompanyQuery({ id: companyId });
  const [updateBankingDetails, { isLoading: isBankingDetailsLoading }] =
    useUpdateBankingDetailsMutation();

  const { data: bankingDetailsDraft = {} } = useGetDraftQuery({ id: userDetails?.id, draftName });
  const initialValues = mergeResponce(companyDetails?.banking_detail, bankingDetailsDraft.payload);
  const [updateDraft] = useDraftMutation();

  const handleBankingDetailsUpdate = (params) => {
    const { account_name, account_number, bank_name, bank_branch_code } = params;
    const requestBody = {
      account_name,
      account_number,
      bank_name,
      bank_branch_code,
    };

    updateBankingDetails({ company_id: companyId, payload: requestBody })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  };

  const saveAsDraft = (values) => {
    const payload = generateProfileDraftBody(values, draftName);

    updateDraft({ id: userDetails?.id, payload })
      .unwrap()
      .then(() => processPortalFormSuccess(toastRef))
      .catch(({ data: { errors }, status }) =>
        handleServerValidationErrors(toastRef, status, errors),
      );
  }

  return (
    <div className="personal-details">
      <h3 className="personal-details__title">{t('profile.banking_details.title')}</h3>
      <Form
        onSubmit={handleBankingDetailsUpdate}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, hasValidationErrors, values }) => (
          <form
            onSubmit={(event) => {
              if (hasValidationErrors) {
                toastRef?.current?.notify(<Toastify type="error" />, errorToastConfig);
              }
              handleSubmit(event);
            }}
            className="margin-y-32px margin-x-24px"
          >
            <fieldset disabled={!isEditable}>
              <p className="personal-details__form--title">
                {t('profile.banking_details.form.title')}
              </p>
              <div className="grid">
                <Field name="account_name" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.banking_details.form.account_name.label')}
                      placeholder={t('profile.banking_details.form.account_name.placeholder')}
                      required
                      className="col-6 col-bleed margin-t-16px personal-details__form--col1"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="account_number" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.banking_details.form.account_number.label')}
                      placeholder={t('profile.banking_details.form.account_number.placeholder')}
                      required
                      size="small"
                      className="col-6 col-bleed margin-t-16px personal-details__form--col2"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              <div className="grid">
                <Field name="bank_name" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.banking_details.form.bank.label')}
                      placeholder={t('profile.banking_details.form.bank.placeholder')}
                      required
                      className="col-6 col-bleed margin-t-20px personal-details__form--col1"
                      error={!meta.modifiedSinceLastSubmit && (meta?.error || meta?.submitError)}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
                <Field name="bank_branch_code" validate={requiredField}>
                  {({ input, meta }) => (
                    <Input
                      label={t('profile.banking_details.form.branch_code.label')}
                      placeholder={t('profile.banking_details.form.branch_code.placeholder')}
                      required
                      size="small"
                      className="col-6 col-bleed margin-t-20px personal-details__form--col2"
                      error={meta?.error || meta?.submitError}
                      touched={meta.touched}
                      value={input.value}
                      onChange={input.onChange}
                      disabled={!isEditable}
                    />
                  )}
                </Field>
              </div>
              {isEditable && (
                <>
                  <Button
                    className="personal-details__submit-btn"
                    label={t('profile.submit')}
                    submitType="submit"
                    type="success"
                    disabled={submitting || isBankingDetailsLoading}
                  />
                  <Button
                    className="personal-details__draft-btn"
                    label="Save as draft"
                    onClick={() => saveAsDraft(values)}
                    type="default"
                    disabled={submitting}
                  />
                </>
              )}
            </fieldset>
          </form>
        )}
      />
    </div>
  );
};

BankingDetailsForm.propTypes = {
  companyId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  toastRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  userDetails: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

BankingDetailsForm.defaultProps = {
  isEditable: false,
  toastRef: null,
};

export default BankingDetailsForm;
